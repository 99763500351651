import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Overlay, Popover, PopoverBody } from "react-bootstrap";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Urls } from "../../util";
import "./SearchComponent.scss";
import { SearchComponentProps } from "./SearchComponentUtil";

export const SearchComponent = ({ inline }: SearchComponentProps) => {
  const element = inline ? <Inline /> : <Pop />;
  return element;
};

const Pop = () => {
  const containerRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  const handleShow = (showValue: boolean) => {
    setShowPopover(showValue);
  };

  const handleButtonClick = (e: any) => {
    setShowPopover(!showPopover);
  };

  const handleOverlayClose = () => {
    handleShow(false);
  };

  return (
    <div ref={containerRef} className={cn("pop")}>
      <button className={cn("pop-button")} onClick={handleButtonClick}>
        <FontAwesomeIcon icon={faSearch} className={cn("pop-button-icon")} />
      </button>
      <Overlay
        show={showPopover}
        target={containerRef.current}
        container={containerRef.current}
        placement="bottom-end"
        rootClose={true}
        rootCloseEvent="click"
        onHide={handleOverlayClose}
      >
        <Popover className={cn("pop-popover")}>
          <PopoverBody className={cn("pop-popover-body")}>
            <Content focusText={true} onSubmit={handleOverlayClose} />
          </PopoverBody>
        </Popover>
      </Overlay>
    </div>
  );
};

const Inline = () => {
  return (
    <div className={cn("inline")}>
      <Content />
    </div>
  );
};

const Content = ({ focusText = false, onSubmit }: { focusText?: boolean; onSubmit?: Function }) => {
  const textRef = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (focusText) {
      textRef?.current?.focus();
    }
  }, [focusText]);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleText(e.target);
    }
  };

  const handleText = (target: any) => {
    const symbol: string = target.value.toUpperCase().trim();
    if (symbol.length > 0) {
      navigateToSymbol(navigate, symbol);
      target.blur();
      target.value = "";
      onSubmit && onSubmit();
    }
  };

  return (
    <div className={cn("content")}>
      <input
        ref={textRef}
        className={`${cn("content-text")}`}
        type="search"
        placeholder="Symbol"
        aria-label="Search"
        autoCapitalize="characters"
        onKeyDown={handleKeyDown}
      />
      <button className={cn("content-button")} onClick={() => handleText(textRef.current)}>
        <FontAwesomeIcon icon={faSearch} className={cn("content-button-icon")} />
      </button>
    </div>
  );
};

const navigateToSymbol = (navigate: NavigateFunction, symbol: string) => {
  navigate(Urls.symbolUrl(symbol));
};

const cn = (suffix?: string) => {
  return `SearchComponent${suffix ? `-${suffix}` : ""}`;
};

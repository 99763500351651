import { AccessToken, Settings, UserInfo } from "../../types/types";

export enum AccessTokenAction {
  ACCESS_TOKEN_UPDATE = "ACCESS_TOKEN_UPDATE"
}

export const accessTokenReducer = (
  state: AccessToken | null = null,
  action: { type: AccessTokenAction; payload: AccessToken }
) => {
  switch (action.type) {
    case AccessTokenAction.ACCESS_TOKEN_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export enum UserInfoAction {
  USER_INFO_UPDATE = "USER_INFO_UPDATE"
}

export const userInfoReducer = (
  state: UserInfo | null = null,
  action: { type: UserInfoAction; payload: UserInfo | null }
) => {
  switch (action.type) {
    case UserInfoAction.USER_INFO_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const settingsInitialState: Settings = {};

export enum SettingsAction {
  UPDATE = "SETTINGS_UPDATE"
}

export const settingsReducer = (state: Settings, action: { type: SettingsAction; payload: Settings }) => {
  switch (action.type) {
    case SettingsAction.UPDATE:
      return { ...action.payload };
    default:
      return state;
  }
};

export enum AppAlertAction {
  UPDATE = "APP_ALERT_UPDATE"
}

export const appAlertReducer = (state: any = null, action: { type: AppAlertAction; payload: any }) => {
  switch (action.type) {
    case AppAlertAction.UPDATE:
      return action.payload;
    default:
      return state;
  }
};

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faBusinessTime,
  faCircleExclamation,
  faMoneyCheckDollar,
  faRepeat,
  faUmbrellaBeach,
  faUserXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUserInfo } from "../../store/storeApi";
import { Urls } from "../../util";
import { LoginModal } from "../login/LoginComponent";
import { TitleText } from "../titletext/TitleText";
import "./HomeComponent.scss";

export const HomeComponent = () => {
  const [showLogin, setShowLogin] = useState(false);
  const { isLoggedIn } = useUserInfo();
  const heroRef = useRef<HTMLDivElement>(null);
  const [paddingTop, setPaddingTop] = useState(0);

  useEffect(() => {
    document.title = "Good Stock Options";
  }, [showLogin]);

  useLayoutEffect(() => {
    setPaddingTop(heroRef.current?.clientHeight || 0);
  }, []);

  return (
    <div className={cn()}>
      <div ref={heroRef} className={cn("hero")}>
        <h2>
          <TitleText text="good stock options .com" />
        </h2>

        <hr />

        <div className={cn("hero-content")}>
          <div className={cn("hero-content-text")}>
            <HeroItem icon={faMoneyCheckDollar}>Earn income with options.</HeroItem>
            <HeroItem icon={faRepeat}>Use simple, repeatable strategies.</HeroItem>
            <HeroItem icon={faBusinessTime}>Save time.</HeroItem>
            <HeroItem icon={faUmbrellaBeach}>Do something else.</HeroItem>
          </div>
        </div>

        <hr />

        <div className={cn("hero-action")}>
          {!isLoggedIn && (
            <button className={cn("hero-action-button")} onClick={() => setShowLogin(true)}>
              Get Started
            </button>
          )}

          {isLoggedIn && (
            <Link to={Urls.myOptionsUrl()}>
              <button className={cn("hero-action-button")}>Go to My Options</button>
            </Link>
          )}
        </div>
      </div>
      <div className={cn("hero-spacer")} key={paddingTop} style={{ height: `${paddingTop}px` }} />

      {showLogin && <LoginModal onHide={() => setShowLogin(false)} />}

      <CardGrid />
    </div>
  );
};

const HeroItem = ({ icon, children }: { icon: IconProp; children: any }) => {
  return (
    <div className={cn("hero-content-text-item")}>
      <FontAwesomeIcon className={cn("hero-content-text-item-icon")} icon={icon} />
      <div>{children}</div>
    </div>
  );
};

const CardGrid = () => {
  return (
    <div className={cn("grid")}>
      <HomeCard icon={faMoneyCheckDollar} title="Earn Income">
        <p>Earn a regular income with good stock options based on rules instead of emotions.</p>
        <p>
          These rules are based on strategies that have worked for us. We'll add support for
          creating custom rules over time.
        </p>
      </HomeCard>

      <HomeCard icon={faBusinessTime} title="Save Time">
        <p>
          Instead of burrowing through endless options chains, we narrow it down and present up to 3
          good options for any stock based on these rules.
        </p>
        <p>Use it often enough and the time savings add up.</p>
      </HomeCard>

      <HomeCard icon={faRepeat} title="Simplify and Repeat">
        <p>
          The key to earning a regular income is simplifying your strategies and selling options on
          a regular basis.
        </p>
        <p>
          The simplification comes from narrowing option selling to only covered calls and secured
          puts and doing so in a rules based manner.
        </p>
        <p>
          Finally, we typically sell short dated options. Couple that with the aforementioned time
          savings and you have a process that's repeatable over time.
        </p>
      </HomeCard>

      <HomeCard warn icon={faUserXmark} title="Not for Everyone">
        <p>
          Since we are net option sellers and don't do margin, you need to possess at least 100
          shares of a stock to sell covered calls or have enough balance to buy 100 shares of a
          stock for secured puts.
        </p>
        <p>We realize this is a significant ask and hence not for everyone.</p>
      </HomeCard>

      <HomeCard warn icon={faBan} title="No #YOLO">
        <p>
          The strategies we use won't make anyone rich overnight. We don't swing for the fences. We
          don't advocate margin.
        </p>
        <p>
          Our strategies are about diligent application of our rules that generate a decent income
          over time.
        </p>
      </HomeCard>

      <HomeCard warn icon={faCircleExclamation} title="No Free Lunches">
        <p>Investing, let alone trading options, is not without risk. Know what the risks are.</p>
        <p>
          When selling covered calls, be prepared for your shares to be called away on occasion.
          When selling secured puts, be prepared for shares to be put to you on occasion.
        </p>
        <p>
          Finally, use this site for education and information purposes only and not as financial
          advice.
        </p>
      </HomeCard>
    </div>
  );
};

const HomeCard = ({
  icon,
  title,
  warn,
  children
}: {
  icon: any;
  title: string;
  warn?: boolean;
  children: any;
}) => {
  return (
    <div className={`${cn("card")} ${warn ? cn("card-warn") : ""}`}>
      <div className={cn("card-icon")}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={cn("card-title")}>{title}</div>
      <div className={cn("card-body")}>{children}</div>
    </div>
  );
};

const cn = (suffix?: string) => {
  return `HomeComponent${suffix ? `-${suffix}` : ""}`;
};

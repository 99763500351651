import { useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import GsoService from "../../services/GsoService";
import { Stock, StockOption, StockOptions, StockOptionType } from "../../types/types";
import { fractionToPercentString, toCurrencyString, toPercentString, Urls } from "../../util";
import { FollowButton } from "../follow/FollowButton";
import { TitleText } from "../titletext/TitleText";
import "./OptionsPanelComponent.scss";
import {
  getOptionsTitle,
  getStockIndicator,
  OptionsPanelComponentProps
} from "./OptionsPanelComponentUtil";

export const OptionsPanelComponent = ({
  symbol,
  standalone = false,
  optionType
}: OptionsPanelComponentProps) => {
  const location = useLocation();
  const [options, setOptions] = useState<StockOptions | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    GsoService.gso(symbol)
      .then((data) => {
        setOptions(data);
      })
      .catch((e: Error) => {
        setError(true);
      });
  }, [symbol, location.key]);

  useEffect(() => {
    if (standalone) {
      document.title = `${symbol.toUpperCase()} | Good Stock Options`;
    }
  }, [standalone, symbol]);

  return (
    <div className={cn()}>
      {options ? (
        <Content options={options} optionType={optionType} standalone={standalone} />
      ) : error ? (
        <Error symbol={symbol} />
      ) : (
        <PlaceHolderContent symbol={symbol} />
      )}
    </div>
  );
};

const Error = ({ symbol }: { symbol: string }) => {
  return (
    <>
      <div className={cn("error")}>
        <div className={cn("error-symbol")}>{symbol}</div>
        <div className={cn("error-message")}>Sorry, no data was found for this symbol.</div>
      </div>
    </>
  );
};

const PlaceHolderContent = ({ symbol }: { symbol: string }) => {
  return (
    <>
      <div className={cn("placeholder")}>
        <div className={`${cn("placeholder-symbol")} placeholder-wave`}>{symbol}</div>
        <div className={cn("placeholder-options")}>
          <Placeholder as="p" animation="wave">
            <Placeholder xs={10} />
          </Placeholder>
          <Placeholder as="p" animation="wave">
            <Placeholder xs={10} />
          </Placeholder>
          <Placeholder as="p" animation="wave">
            <Placeholder xs={10} />
          </Placeholder>
        </div>
      </div>
    </>
  );
};

const Content = ({
  options: { stock, coveredCalls, securedPuts },
  standalone,
  optionType
}: {
  options: StockOptions;
  standalone: boolean;
  optionType?: StockOptionType;
}) => {
  const showCc = standalone || optionType === StockOptionType.coveredCall;
  const showSp = standalone || optionType === StockOptionType.securedPut;
  const stockIndicator = getStockIndicator(stock.percentChange);

  return (
    <div className={cn("content")}>
      <StockComponent stock={stock} stockIndicator={stockIndicator} standalone={standalone} />
      {showCc && (
        <OptionsComponent
          stock={stock}
          optionType={StockOptionType.coveredCall}
          options={coveredCalls}
          showTitle={standalone}
        />
      )}
      {showSp && (
        <OptionsComponent
          stock={stock}
          optionType={StockOptionType.securedPut}
          options={securedPuts}
          showTitle={standalone}
        />
      )}
    </div>
  );
};

const StockComponent = ({
  stock: { symbol, description, lastPrice, percentChange },
  stockIndicator,
  standalone
}: {
  stock: Stock;
  stockIndicator: string;
  standalone: boolean;
}) => {
  return (
    <div
      className={`${cn("stock")} ${!standalone && cn("stock-not-standalone")} ${`${cn(
        "stock-indicator"
      )}-${stockIndicator}`}`}
    >
      <div className={cn("stock-description")}>{description}</div>
      <div className={cn("stock-details")}>
        <Link to={Urls.symbolUrl(symbol)} className={cn("stock-symbol")}>
          <span className={cn("stock-symbol-text")}>{symbol}</span>
        </Link>
        <div className={cn("stock-price")}>
          <div className={cn("stock-last-price")}>{toCurrencyString(lastPrice)}</div>
          {percentChange !== 0 && (
            <div className={cn("stock-percent-change")}>
              ({toPercentString(percentChange, true)})
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const OptionsComponent = ({
  optionType,
  options,
  stock,
  showTitle
}: {
  optionType: StockOptionType;
  stock: Stock;
  options: StockOption[];
  showTitle: boolean;
}) => {
  const optionElements = () =>
    options.map((option, optionIndex) => (
      <OptionComponent key={optionIndex} option={option} optionType={optionType} stock={stock} />
    ));

  return (
    <div className={cn("options")}>
      {showTitle && (
        <div className={`${cn("options-title")} ${`${cn("options-title")}-${optionType}`}`}>
          <TitleText className={cn("options-title-title")} text={getOptionsTitle(optionType)} />
          <FollowButton
            className={cn("options-title-follow")}
            symbol={stock.symbol}
            optionType={optionType}
          />
        </div>
      )}
      <div className={cn("options-content")}>{optionElements()}</div>
    </div>
  );
};

const OptionComponent = ({
  option: { expirationDate, daysToExpiration, strikePrice, lastPrice, probability },
  stock
}: {
  optionType: StockOptionType;
  stock: Stock;
  option: StockOption;
}) => {
  return (
    <div className={cn("option")}>
      <div className={cn("option-expiration")}>
        <div className={cn("option-expiration-date")}>{expirationDate}</div>
        <div className={cn("option-expiration-days")}>
          {`${daysToExpiration} day${daysToExpiration !== 1 ? "s" : ""}`}
        </div>
      </div>
      <div className={cn("option-details")}>
        <div className={cn("option-strike")}>
          <div className={cn("option-column-title")}>Strike</div>
          <div className={cn("option-strike-price")}>{toCurrencyString(strikePrice)}</div>
          <div className={cn("option-strike-percent")}>
            {fractionToPercentString(strikePrice - stock.lastPrice, stock.lastPrice, true)}
          </div>
        </div>
        <div className={cn("option-last")}>
          <div className={cn("option-column-title")}>Last</div>
          <div className={cn("option-last-price")}>{toCurrencyString(lastPrice)}</div>
          <div className={cn("option-last-percent")}>
            {fractionToPercentString(lastPrice, stock.lastPrice)}
          </div>
        </div>
        <div className={cn("option-probability")}>
          <div className={cn("option-column-title")}>Prob.</div>
          <div className={cn("option-probability-percent")}>{toPercentString(probability)}</div>
        </div>
      </div>
    </div>
  );
};

const cn = (suffix?: string) => {
  return `OptionsPanelComponent${suffix ? `-${suffix}` : ""}`;
};

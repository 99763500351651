import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserInfo } from "../../store/storeApi";
import { StockOptionType } from "../../types/types";
import { Urls } from "../../util";
import { FollowIcon } from "../follow/FollowButton";
import { LoginModal } from "../login/LoginComponent";
import { OptionsPanelComponent } from "../optionspanel/OptionsPanelComponent";
import { getOptionsTitle } from "../optionspanel/OptionsPanelComponentUtil";
import { TitleText } from "../titletext/TitleText";
import "./MyOptionsComponent.scss";

export const MyOptionsComponent = () => {
  useEffect(() => {
    document.title = "My Options | Good Stock Options";
  }, []);

  const { userInfo, isLoggedIn } = useUserInfo();
  const [showLogin, setShowLogin] = useState(!isLoggedIn);

  useEffect(() => {
    setShowLogin(!isLoggedIn);
  }, [isLoggedIn]);

  const ccSymbols = userInfo?.symbols?.coveredCall || [];
  const spSymbols = userInfo?.symbols?.securedPut || [];
  const noSymbols = ccSymbols.length === 0 && spSymbols.length === 0;

  const elements = [];
  if (noSymbols) {
    elements.push(<NoSymbols key={elements.length} />);
  } else {
    if (ccSymbols.length > 0) {
      elements.push(
        <OptionType
          key={elements.length}
          symbols={ccSymbols}
          optionType={StockOptionType.coveredCall}
        />
      );
    }
    if (spSymbols.length > 0) {
      elements.push(
        <OptionType
          key={elements.length}
          symbols={spSymbols}
          optionType={StockOptionType.securedPut}
        />
      );
    }
  }

  return (
    <div className={cn()}>
      {elements}
      {showLogin && <LoginModal onHide={() => setShowLogin(false)} />}
    </div>
  );
};

const NoSymbols = () => {
  return (
    <div className={cn("none")}>
      <div className={cn("none-title")}>
        <TitleText text="My Options" />
      </div>
      <div className={cn("none-message")}>
        Follow options and view them all here. Look for the <FollowIcon isFollowed={false} /> icon.
      </div>
      <div className={cn("none-action")}>
        <FollowIcon isFollowed={false} />
        <FontAwesomeIcon icon={faRightLong} className={cn("none-action-arrow")} />
        <FollowIcon isFollowed={true} />
      </div>
      <div className={cn("none-example-message")}>
        <div className={cn("none-example-try")}>Try for yourself?</div>
        <div className={cn("none-example-steps")}>
          <li>
            Follow options for any stock, say{" "}
            <Link to={Urls.symbolUrl("AAPL")} className={cn("none-example-stock")}>
              <span>AAPL</span>
            </Link>
            .
          </li>
          <li>Come back to this page.</li>
          <li>You can always unfollow later.</li>
        </div>
      </div>
    </div>
  );
};

const OptionType = ({
  symbols,
  optionType
}: {
  symbols: string[];
  optionType: StockOptionType;
}) => {
  const panelElements = () =>
    symbols.map((symbol, symbolIndex) => (
      <OptionsPanelComponent key={symbolIndex} symbol={symbol} optionType={optionType} />
    ));

  return (
    <div className={cn("type")}>
      <div className={cn("type-title")}>
        <TitleText text={getOptionsTitle(optionType)} />
      </div>
      <div className={cn("type-content")}>{panelElements()}</div>
    </div>
  );
};

const cn = (suffix?: string) => {
  return `MyOptionsComponent${suffix ? `-${suffix}` : ""}`;
};

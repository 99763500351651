export const Urls = {
  symbolUrl: (symbol: string) => {
    return `/$${symbol}`;
  },
  myOptionsUrl: () => {
    return "/myoptions";
  },
  homeUrl: () => {
    return "/";
  }
};

export const toCurrencyString = (amount: number) => {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });
};

const percentFormatter = new Intl.NumberFormat("default", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

export const toPercentString = (percent: number, signed: boolean = false) => {
  return valueToPercentString(percent / 100, signed);
};

export const fractionToPercentString = (numerator: number, denominator: number, signed: boolean = false) => {
  return valueToPercentString(numerator / denominator, signed);
};

const valueToPercentString = (value: number, signed: boolean) => {
  return `${signed && value > 0 ? "+" : ""}${percentFormatter.format(value)}`;
};

import { useState } from "react";
import GsoService, { useGsoService } from "../services/GsoService";
import { AccessToken, Settings, StockOptionType, UserInfo } from "../types/types";
import { accessTokenDb, settingsDb, userInfoDb } from "./database/gsodb";
import { useStore } from "./GlobalStateProvider";
import { AccessTokenAction, AppAlertAction, SettingsAction, UserInfoAction } from "./reducers/reducers";

export const useAccessToken = () => {
  const { accessTokenState, accessTokenDispatch } = useStore();

  const dbUpdateAndDispatch = (accessToken: AccessToken | null) => {
    if (accessToken == null) {
      accessTokenDb.delete();
    } else {
      accessTokenDb.set(accessToken);
    }
    accessTokenDispatch({ type: AccessTokenAction.ACCESS_TOKEN_UPDATE, payload: accessToken });
  };

  return {
    accessToken: accessTokenState as AccessToken | null,
    setAccessToken: (accessToken: AccessToken) => {
      dbUpdateAndDispatch(accessToken);
    },
    deleteAccessToken: () => {
      dbUpdateAndDispatch(null);
    }
  };
};

export const useUserInfo = () => {
  const { userInfoState, userInfoDispatch, loading } = useStore();
  const { accessToken } = useAccessToken();
  useGsoService();

  const userInfo = userInfoState as UserInfo | null;

  const isLoggedIn = !!accessToken;

  const dbUpdateAndDispatch = (userInfo: UserInfo | null) => {
    if (userInfo == null) {
      userInfoDb.delete();
    } else {
      userInfoDb.set(userInfo);
    }
    userInfoDispatch({ type: UserInfoAction.USER_INFO_UPDATE, payload: userInfo });
  };

  const setUserInfo = (userInfo: UserInfo) => {
    dbUpdateAndDispatch(userInfo);
  };

  const deleteUserInfo = () => {
    dbUpdateAndDispatch(null);
  };

  const getSymbols = (optionType: StockOptionType): string[] => {
    switch (optionType) {
      case StockOptionType.coveredCall:
        return userInfo?.symbols?.coveredCall || [];
      case StockOptionType.securedPut:
        return userInfo?.symbols?.securedPut || [];
      default:
        return [];
    }
  };

  const isSymbolFollowed = (symbol: string, optionType: StockOptionType): boolean => {
    return getSymbols(optionType).some((elem) => elem === symbol);
  };

  const followSymbol = (symbol: string, optionType: StockOptionType, onSuccess?: Function) => {
    if (accessToken != null) {
      GsoService.follow(symbol, optionType)
        .then((data) => {
          setUserInfo(data);
          onSuccess && onSuccess();
        })
        .catch((e: Error) => console.log(e));
    }
  };

  const unfollowSymbol = (symbol: string, optionType: StockOptionType, onSuccess?: Function) => {
    if (accessToken != null) {
      GsoService.unfollow(symbol, optionType)
        .then((data) => {
          setUserInfo(data);
          onSuccess && onSuccess();
        })
        .catch((e: Error) => console.log(e));
    }
  };

  const getUserInfo = () => {
    if (accessToken != null) {
      GsoService.user()
        .then((data) => {
          setUserInfo(data);
        })
        .catch((e: Error) => console.log(e));
    }
  };

  return {
    loading,
    userInfo,
    isLoggedIn,
    setUserInfo,
    deleteUserInfo,
    isSymbolFollowed,
    followSymbol,
    unfollowSymbol,
    getUserInfo
  };
};

export const useFollow = (symbol: string, optionType: StockOptionType) => {
  const { followSymbol, unfollowSymbol, isSymbolFollowed } = useUserInfo();
  const [isFollowed, setIsFollowed] = useState(isSymbolFollowed(symbol, optionType));

  return {
    isFollowed,
    follow: () => {
      followSymbol(symbol, optionType, () => setIsFollowed(true));
    },
    unfollow: () => {
      unfollowSymbol(symbol, optionType, () => setIsFollowed(false));
    }
  };
};

export const useSettings = () => {
  const { settingsState, settingsDispatch } = useStore();

  const settings = settingsState as Settings;
  const { followTipDismissed } = settings;

  const dbUpdateAndDispatch = (settings: Settings) => {
    settingsDb.set(settings);
    settingsDispatch({ type: SettingsAction.UPDATE, payload: settings });
  };

  return {
    followTipDismissed: !!followTipDismissed,
    followTipOff: () => {
      dbUpdateAndDispatch({ ...settings, followTipDismissed: true });
    }
  };
};

export const useAppAlert = () => {
  const { appAlertState, appAlertDispatch } = useStore();

  const dispatch = (appAlert: any) => {
    appAlertDispatch({ type: AppAlertAction.UPDATE, payload: appAlert });
  };

  return {
    appAlert: appAlertState as any,
    setAppAlert: (appAlert: any) => {
      dispatch(appAlert);
    }
  };
};

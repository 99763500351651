import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useFollow, useSettings, useUserInfo } from "../../store/storeApi";
import { StockOptionType } from "../../types/types";
import { LoginModal } from "../login/LoginComponent";
import "./FollowButton.scss";
import { FollowButtonProps } from "./FollowButtonUtil";

export const FollowButton = ({ symbol, optionType, className = "" }: FollowButtonProps) => {
  const { isFollowed, follow, unfollow } = useFollow(symbol, optionType);
  const { isLoggedIn } = useUserInfo();
  const [showLogin, setShowLogin] = useState(false);
  const [justLoggedIn, setJustLoggedIn] = useState(false);

  const { followTipDismissed, followTipOff } = useSettings();
  const [showFollowTip, setShowFollowTip] = useState(false);
  const followRef = useRef(null);

  useEffect(() => {
    if (justLoggedIn) {
      handleClickLoggedIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [justLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn && !followTipDismissed && optionType === StockOptionType.coveredCall) {
      setShowFollowTip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (!isLoggedIn) {
      setShowLogin(true);
      return;
    }
    handleClickLoggedIn();
  };

  const handleClickLoggedIn = () => {
    if (isFollowed) {
      unfollow();
    } else {
      follow();
    }
  };

  const handleHideLogin = () => {
    setShowLogin(false);
  };

  const handleJustLoggedIn = () => {
    setJustLoggedIn(true);
    setShowLogin(false);
  };

  const handleHideFollowTip = () => {
    setShowFollowTip(false);
    followTipOff();
  };

  return (
    <>
      <div key={`${isFollowed} ${showLogin}`} className={`${cn()} ${className}`}>
        {showLogin && <LoginModal onHide={handleHideLogin} onLogin={handleJustLoggedIn} />}
        <button ref={followRef} className={cn("button")} onClick={handleClick}>
          <FollowIcon isFollowed={isFollowed} />
        </button>

        <Overlay
          show={showFollowTip}
          container={followRef.current}
          target={followRef.current}
          placement="bottom-end"
          rootClose={true}
          rootCloseEvent="click"
          onHide={handleHideFollowTip}
        >
          <Popover className={cn("tip-popover")}>
            <Popover.Body className={cn("tip-popover-body")}>
              Tap <FollowIcon isFollowed={false} /> to follow options.
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </>
  );
};

export const FollowIcon = ({
  isFollowed,
  className = ""
}: {
  isFollowed: boolean;
  className?: string;
}) => {
  const iconClassName = `${cn("button-icon-")}${isFollowed ? "followed" : "not-followed"}`;

  return (
    <FontAwesomeIcon
      icon={faStar}
      className={`${cn("button-icon")} ${iconClassName} ${className}`}
    />
  );
};

const cn = (suffix?: string) => {
  return `FllwButton${suffix ? `-${suffix}` : ""}`;
};

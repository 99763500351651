import { useEffect, useReducer, useState } from "react";
import { accessTokenDb, settingsDb, userInfoDb } from "./database/gsodb";
import {
  AccessTokenAction,
  accessTokenReducer,
  appAlertReducer,
  SettingsAction,
  settingsInitialState,
  settingsReducer,
  UserInfoAction,
  userInfoReducer
} from "./reducers/reducers";

const useGlobalState = () => {
  const [accessTokenState, accessTokenDispatch] = useReducer(accessTokenReducer, null);
  const [userInfoState, userInfoDispatch] = useReducer(userInfoReducer, null);
  const [settingsState, settingsDispatch] = useReducer(settingsReducer, settingsInitialState);
  const [appAlertState, appAlertDispatch] = useReducer(appAlertReducer, null);
  const [loading, setLoading] = useState(true);

  const getInitialState = async () => {
    const [accessToken, userInfo, settings] = await Promise.all([
      accessTokenDb.get(),
      userInfoDb.get(),
      settingsDb.get()
    ]);

    if (accessToken) {
      accessTokenDispatch({ type: AccessTokenAction.ACCESS_TOKEN_UPDATE, payload: accessToken });
    }

    if (userInfo) {
      userInfoDispatch({ type: UserInfoAction.USER_INFO_UPDATE, payload: userInfo });
    }

    if (settings) {
      settingsDispatch({ type: SettingsAction.UPDATE, payload: { ...settings } });
    }

    setLoading(false);
  };

  useEffect(() => {
    getInitialState();
  }, []);

  return {
    accessTokenState,
    accessTokenDispatch,
    userInfoState,
    userInfoDispatch,
    settingsState,
    settingsDispatch,
    appAlertState,
    appAlertDispatch,
    loading
  };
};

export default useGlobalState;

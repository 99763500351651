import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars, faHome, faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import "./App.scss";
import { AppAlert } from "./components/appalert/AppAlert";
import { HomeComponent } from "./components/home/HomeComponent";
import { LoginComponent, LoginModal, useLogout } from "./components/login/LoginComponent";
import { MyOptionsComponent } from "./components/myoptions/MyOptionsComponent";
import { OptionsPanelComponent } from "./components/optionspanel/OptionsPanelComponent";
import { SearchComponent } from "./components/search/SearchComponent";
import { TitleText } from "./components/titletext/TitleText";
import { useUserInfo } from "./store/storeApi";
import { Urls } from "./util";

const App = () => {
  const { loading, getUserInfo } = useUserInfo();
  const location = useLocation();

  // Fetch user info from server on load.
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className={cn()}>
      <NavBar />
      <div className={`${cn("content")} container`}>
        <Routes>
          <Route path="/$:symbol" element={<Symbol key={location.key} />} />
          <Route path="/myoptions" element={<MyOptionsComponent key={location.key} />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route index element={<HomeComponent />} />
        </Routes>
      </div>
    </div>
  );
};

const NavBar = () => {
  return (
    <>
      <nav className={`${cn("nav")} navbar navbar-expand sticky-top`}>
        <Link className={cn("nav-name")} to="/">
          <TitleText text="good stock options .com" />
        </Link>

        <SearchComponent inline />
        <SearchComponent inline={false} />
        <NavMenu />
      </nav>
      <AppAlert />
    </>
  );
};

const NavMenu = () => {
  const { isLoggedIn } = useUserInfo();
  const { logout } = useLogout();
  const [showLogin, setShowLogin] = useState(false);

  return (
    <div className={cn("nav-menu")}>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle variant="none" className={cn("nav-menu-toggle")}>
          <NavDropdownToggleItem />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end" className={cn("nav-menu-dropdown")}>
          {!isLoggedIn && (
            <NavDropdownItem icon={faUser} text="Login" onClick={() => setShowLogin(true)} />
          )}
          <NavDropdownItem url={Urls.myOptionsUrl()} icon={faStar} text="My Options" />
          <NavDropdownItem url={Urls.homeUrl()} icon={faHome} text="Home" />
          {isLoggedIn && <NavDropdownItem icon={faUser} text="Logout" onClick={logout} />}
        </Dropdown.Menu>
      </Dropdown>
      {showLogin && <LoginModal onHide={() => setShowLogin(false)} />}
    </div>
  );
};

const NavDropdownToggleItem = () => {
  const { isLoggedIn, userInfo } = useUserInfo();

  if (!isLoggedIn || !!!userInfo) {
    return (
      <div className={cn("nav-menu-toggle-icon")}>
        <FontAwesomeIcon icon={faBars} />
      </div>
    );
  }

  const firstLetter = (s: string) => {
    return s.length > 0 ? s[0] : "";
  };

  const tokens = (userInfo.name || "").split(" ");
  const initials = `${firstLetter(tokens[0])}${firstLetter(tokens.length > 1 ? tokens[1] : "")}`;

  return <div className={cn("nav-menu-toggle-initials")}>{initials}</div>;
};

const NavDropdownItem = ({
  url,
  icon,
  text,
  onClick
}: {
  url?: string;
  icon: IconProp;
  text: string;
  onClick?: Function;
}) => {
  const handleClick = () => {
    onClick && onClick();
  };

  if (url) {
    return (
      <Dropdown.Item className={cn("nav-menu-dropdown-item")} as={Link} to={url}>
        <div className={cn("nav-menu-dropdown-item-icon")}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div>{text}</div>
      </Dropdown.Item>
    );
  }

  return (
    <Dropdown.Item className={cn("nav-menu-dropdown-item")} as={Button} onClick={handleClick}>
      <div className={cn("nav-menu-dropdown-item-icon")}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div>{text}</div>
    </Dropdown.Item>
  );
};

const Symbol = () => {
  const { symbol } = useParams() as {
    symbol: string;
  };

  return <OptionsPanelComponent symbol={symbol} standalone />;
};

const cn = (suffix?: string) => {
  return `App${suffix ? `-${suffix}` : ""}`;
};

export default App;

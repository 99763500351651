import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import googleSigninImageFocus from "../../assets/images/google-signin-focus.png";
import googleSigninImage from "../../assets/images/google-signin.png";
import logo from "../../assets/logo/logo.svg";
import GsoService from "../../services/GsoService";
import { useAccessToken, useAppAlert, useUserInfo } from "../../store/storeApi";
import { Urls } from "../../util";
import { TitleText } from "../titletext/TitleText";
import "./LoginComponent.scss";
import { LoginComponentProps, LoginModalProps } from "./LoginComponentUtil";

export const LoginModal = ({ onHide, onLogin }: LoginModalProps) => {
  const handleHide = () => {
    onHide && onHide();
  };

  return (
    <Modal className={cn("modal")} show={true} backdrop="static" centered onHide={handleHide}>
      <Modal.Header className={cn("modal-header")} closeButton closeVariant="white">
        <strong>
          <DefaultLoginHeader />
        </strong>
      </Modal.Header>
      <Modal.Body className={cn("modal-body")}>
        <LoginComponent onLogin={onLogin} onLoginDefault={handleHide} />
      </Modal.Body>
    </Modal>
  );
};

export const LoginComponent = ({ onLogin, onLoginDefault }: LoginComponentProps) => {
  const [googleMouseOver, setGoogleMouseOver] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginFailure, setLoginFailure] = useState(false);
  const { setAccessToken } = useAccessToken();
  const { setUserInfo } = useUserInfo();
  const navigate = useNavigate();
  const { setAppAlert } = useAppAlert();

  useEffect(() => {
    document.title = "Login | Good Stock Options";
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoggedIn(true);
      setLoginFailure(false);
      GsoService.googleAuth(tokenResponse.access_token)
        .then((data) => {
          setAccessToken({ token: data.accessToken, created: new Date() });
          setUserInfo(data.userInfo);
          if (data.userInfo?.name) {
            const firstName = data.userInfo.name.split(" ")[0].trim();
            setAppAlert(`Welcome, ${firstName}!`);
          }

          if (onLogin) {
            onLogin(data.userInfo);
          } else {
            onLoginDefault && onLoginDefault();
            navigate(Urls.myOptionsUrl());
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setLoggedIn(false);
          setLoginFailure(true);
        });
    },
    onError: (errorResponse) => {
      setLoggedIn(false);
      setLoginFailure(true);
      console.log(errorResponse);
    }
  });

  const header = loggedIn ? (
    "Login Successful!"
  ) : loginFailure ? (
    "Login Failed"
  ) : (
    <DefaultLoginHeader />
  );
  const message = loggedIn
    ? "Getting your account information..."
    : loginFailure
    ? "Please try again."
    : "Login to follow options and access your account on any device.";

  const defaultLoginContent = (
    <div className={cn("google")}>
      <button
        className={cn("google-button")}
        onClick={() => googleLogin()}
        onMouseOver={() => setGoogleMouseOver(true)}
        onMouseOut={() => setGoogleMouseOver(false)}
      >
        <img
          className={cn("google-image")}
          alt="Sign in with Google"
          src={googleMouseOver ? googleSigninImageFocus : googleSigninImage}
        />
      </button>
    </div>
  );

  const loggingInContent = (
    <div className={cn("logging-in")}>
      <img
        className={`${cn("logging-in-logo")} ${cn("bounce-gentle")}`}
        src={logo}
        alt="Logging in"
      />
    </div>
  );

  const loginContent = loggedIn ? loggingInContent : defaultLoginContent;

  return (
    <LoginTemplate header={header} message={message} messageWarning={loginFailure}>
      {loginContent}
    </LoginTemplate>
  );
};

const DefaultLoginHeader = () => {
  return (
    <>
      Login to <TitleText text="good stock options .com" />
    </>
  );
};

const LoginTemplate = ({
  header,
  message,
  messageWarning,
  children
}: {
  header: any;
  message: any;
  messageWarning: boolean;
  children: any;
}) => {
  return (
    <div className={cn()}>
      <div className={cn("container")}>
        <div className={cn("header")}>{header}</div>
        <div className={cn("content")}>
          <div className={`${cn("message")} ${messageWarning ? cn("message-warning") : ""}`}>
            {message}
          </div>
          <div className={cn("content-main")}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const useLogout = () => {
  const { deleteAccessToken } = useAccessToken();
  const { deleteUserInfo } = useUserInfo();
  const navigate = useNavigate();
  const { setAppAlert } = useAppAlert();

  return {
    logout: () => {
      deleteAccessToken();
      deleteUserInfo();
      setAppAlert("Logged out.");
      navigate(Urls.homeUrl());
    }
  };
};

const cn = (suffix?: string) => {
  return `LoginComponent${suffix ? `-${suffix}` : ""}`;
};

import { useEffect, useState } from "react";
import { useAppAlert } from "../../store/storeApi";
import "./AppAlert.scss";

export const AppAlert = () => {
  const { appAlert } = useAppAlert();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!!appAlert);

    if (!!appAlert) {
      const timer = setTimeout(() => setShow(false), 5800);
      return () => clearTimeout(timer);
    }
  }, [appAlert]);

  return <>{show && <div className={cn()}>{appAlert}</div>}</>;
};

const cn = (suffix?: string) => {
  return `AppAlert${suffix ? `-${suffix}` : ""}`;
};

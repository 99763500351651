import { useEffect } from "react";
import http from "../http-common";
import { useAccessToken } from "../store/storeApi";
import { AuthResponse, StockOptions, StockOptionType, UserInfo } from "../types/types";

export const useGsoService = () => {
  const { accessToken } = useAccessToken();

  useEffect(() => {
    if (accessToken != null) {
      http.defaults.headers.common["Authorization"] = `Bearer ${accessToken.token}`;
    } else {
      delete http.defaults.headers.common["Authorization"];
    }
  }, [accessToken]);
};

const gso = (symbol: string): Promise<StockOptions> => {
  return http.get<StockOptions>(`/goodOptions/${symbol}`).then((response) => response.data);
};

const user = (): Promise<UserInfo> => {
  return http.get<UserInfo>("/user").then((response) => response.data);
};

const follow = (symbol: string, optionType: StockOptionType): Promise<UserInfo> => {
  return http.post<UserInfo>(`/user/symbols/${optionType}/${symbol}`).then((response) => response.data);
};

const unfollow = (symbol: string, optionType: StockOptionType): Promise<UserInfo> => {
  return http.delete<UserInfo>(`/user/symbols/${optionType}/${symbol}`).then((response) => response.data);
};

const googleAuth = (googleAccessToken: string): Promise<AuthResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${googleAccessToken}`
    }
  };
  return http.get<AuthResponse>("/auth/google", config).then((response) => response.data);
};

const GsoService = {
  gso,
  user,
  follow,
  unfollow,
  googleAuth
};

export default GsoService;

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar,
  faSearch,
  faBars,
  faHome,
  faInfoCircle,
  faMoneyCheckDollar,
  faBusinessTime,
  faRepeat,
  faUserXmark,
  faBan,
  faCircleExclamation,
  faHeart,
  faUmbrellaBeach,
  faRightLong,
  faUser,
  faCircle
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faStar,
  faSearch,
  faBars,
  faHome,
  faInfoCircle,
  faMoneyCheckDollar,
  faBusinessTime,
  faRepeat,
  faUserXmark,
  faBan,
  faCircleExclamation,
  faHeart,
  faUmbrellaBeach,
  faRightLong,
  faUser,
  faCircle
);

export interface Stock {
  symbol: string;
  description: string;
  lastPrice: number;
  percentChange: number;
}

export interface StockOption {
  symbol: string;
  expirationDate: string;
  daysToExpiration: number;
  strikePrice: number;
  lastPrice: number;
  probability: number;
}

export interface StockOptions {
  stock: Stock;
  coveredCalls: StockOption[];
  securedPuts: StockOption[];
}

export enum StockOptionType {
  coveredCall = "coveredCall",
  securedPut = "securedPut"
}

export interface UserInfo {
  userId: string;
  email: string;
  name: string;
  picture: string;
  symbols?: UserSymbols;
}

export interface UserSymbols {
  coveredCall?: string[];
  securedPut?: string[];
}

export interface AccessToken {
  token: string;
  created: Date;
}

export interface AuthResponse {
  accessToken: string;
  userInfo: UserInfo;
}

export interface Settings {
  followTipDismissed?: boolean;
}

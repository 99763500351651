import { StockOptionType } from "../../types/types";

export interface OptionsPanelComponentProps {
  symbol: string;
  standalone?: boolean;
  optionType?: StockOptionType;
}

export const getOptionsTitle = (stockOptionType: StockOptionType) => {
  switch (stockOptionType) {
    case StockOptionType.coveredCall:
      return "Good Covered Calls";
    case StockOptionType.securedPut:
      return "Good Secured Puts";
    default:
      return "Unknown";
  }
};

export const getStockIndicator = (percentageChange: number) => {
  return percentageChange > 0 ? "plus" : percentageChange < 0 ? "minus" : "neutral";
};

import { DBSchema, openDB } from "idb";
import { AccessToken, Settings, UserInfo } from "../../types/types";

const GSO_DB_NAME = "gsoDb";

const KEY_VALUE_STORE_NAME = "keyValue";

const ACCESS_TOKEN_KEY = "accessToken";
const USER_INFO_KEY = "userInfo";
const SETTINGS_KEY = "settings";

interface GsoDb extends DBSchema {
  keyValue: {
    key: string;
    value: any;
  };
}

const openDb = async () => {
  const gsoDb = await openDB<GsoDb>(GSO_DB_NAME, 2, {
    upgrade(db) {
      const stores = new Set(db.objectStoreNames);
      if (!stores.has(KEY_VALUE_STORE_NAME)) {
        db.createObjectStore(KEY_VALUE_STORE_NAME);
      }
    }
  });
  return gsoDb;
};

// accessToken
export const accessTokenDb = {
  get: async (): Promise<AccessToken> => {
    const db = await openDb();
    return db.get(KEY_VALUE_STORE_NAME, ACCESS_TOKEN_KEY);
  },
  set: async (value: AccessToken) => {
    const db = await openDb();
    await db.put(KEY_VALUE_STORE_NAME, value, ACCESS_TOKEN_KEY);
  },
  delete: async () => {
    const db = await openDb();
    await db.delete(KEY_VALUE_STORE_NAME, ACCESS_TOKEN_KEY);
  }
};

// userInfo
export const userInfoDb = {
  get: async (): Promise<UserInfo> => {
    const db = await openDb();
    return db.get(KEY_VALUE_STORE_NAME, USER_INFO_KEY);
  },
  set: async (value: UserInfo) => {
    const db = await openDb();
    await db.put(KEY_VALUE_STORE_NAME, value, USER_INFO_KEY);
  },
  delete: async () => {
    const db = await openDb();
    await db.delete(KEY_VALUE_STORE_NAME, USER_INFO_KEY);
  }
};

// settings
export const settingsDb = {
  get: async (): Promise<Settings> => {
    const db = await openDb();
    return db.get(KEY_VALUE_STORE_NAME, SETTINGS_KEY);
  },
  set: async (value: Settings) => {
    const db = await openDb();
    await db.put(KEY_VALUE_STORE_NAME, value, SETTINGS_KEY);
  }
};

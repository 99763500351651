import axios from "axios";

export default axios.create({
  // baseURL: "http://192.168.86.27:8080/api",
  // baseURL: "https://goodstockoptions.com/api",
  baseURL: "/api",
  headers: {
    "Content-type": "application/json"
  }
});
